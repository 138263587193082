import { joinBy } from '@ama-selections/ui'
import { MapPinIcon } from 'components/icons'
import { DestinationData, Result as ResultType, SearchResults as SearchResultsType } from './_types'
import classNames from 'classnames'

const SearchResults = ({
  destinations,
  wordpressSlugged = false,
  onClick,
  className,
}: SearchResultsType) => {
  return (
    <div className={classNames('flex flex-col w-full lg:min-h-max mb-15 overflow-y-auto', className?.container)}>
      {
        destinations.map((destination, index) => (
          <Result
            key={`search-result-${destination.__typename}-${destination.id}-${index}`}
            destination={destination}
            wordpressSlugged={wordpressSlugged}
            onClick={onClick}
          />
        ))
      }
    </div>
  )
}

const Result = ({
  destination,
  wordpressSlugged = false,
  onClick,
}: ResultType) => {
  let destinationData: DestinationData
  let value: string
  const slugType = wordpressSlugged ? 'value' : 'slug'

  switch (destination.__typename) {
    case 'DestinationRegion':
      value = joinBy([
        destination.value,
        destination.country.value,
      ])

      destinationData = {
        onClick: {
          country: {
            value: destination.country.value,
            slug: destination.country[slugType],
          },
          region: {
            value: destination.value,
            slug: destination[slugType],
          },
          value: value,
        },
        value: value,
      }
      break

    case 'DestinationSubRegion':
      value = joinBy([
        destination.value,
        destination.region.value,
        destination.region.country.value,
      ])

      destinationData = {
        onClick: {
          country: {
            value: destination.region.country.value,
            slug: destination.region.country[slugType],
          },
          region: {
            value: destination.region.value,
            slug: destination.region[slugType],
          },
          subregion: {
            value: destination.value,
            slug: destination[slugType],
          },

          value: value,
        },
        value: value,
      }
      break

    case 'DestinationCity':
      value = joinBy([
        destination.value,
        destination.subRegion.value,
        destination.subRegion.region.value,
        destination.subRegion.region.country.value,
      ])

      destinationData = {
        onClick: {
          country: {
            value: destination.subRegion.region.country.value,
            slug: destination.subRegion.region.country[slugType],
          },
          region: {
            value: destination.subRegion.region.value,
            slug: destination.subRegion.region[slugType],
          },
          subregion: {
            value: destination.subRegion.value,
            slug: destination.subRegion[slugType],
          },
          city: {
            value: destination.value,
            slug: '',
          },

          value: value,
        },
        value: value,
      }
      break
  }

  return (
    <button onClick={(e) => {
      e.preventDefault()
      onClick(destinationData.onClick)
    }}>
      <div className="flex flex-row items-center gap-12 antialiased cursor-pointer mb-15" >
        <div className="flex items-center justify-center rounded-full w-30 h-30 bg-grey-150 text-16 px-[9px]">
          <MapPinIcon />
        </div>
        <div className="font-bold text-left text-14 text-grey-800 hover:underline">
          {destinationData!.value}
        </div>
      </div>
    </button>
  )
}

export default SearchResults
